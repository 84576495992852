<template>
  <form
    class="contact-form-container vue-form"
    v-on:submit.prevent="onSubmit"
    novalidate
  >
    <fieldset>
      <div class="row">
        <div class="col-sm">
          <label class="label" for="firstname">Your Name*</label>
          <input
            type="text"
            name="firstname"
            :class="{ firstname, error: !formData.firstname.valid }"
            id="name"
            required
            v-model="formData.firstname.value"
          />
          <transition
            enter-active-class="animated fadeInDown"
            leave-active-class="animated fadeOutUp"
            mode="out-in"
          >
            <div class="error-cont" v-if="!formData.firstname.valid">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
              >
                <title>ic_error_48px</title>
                <g fill="#ED4337">
                  <path
                    d="M24 4C12.96 4 4 12.95 4 24s8.96 20 20 20 20-8.95 20-20S35.04 4 24 4zm2 30h-4v-4h4v4zm0-8h-4V14h4v12z"
                  />
                </g>
              </svg>
              <p>Please enter your name</p>
            </div>
          </transition>
        </div>
      </div>

      <div class="row">
        <div class="col-sm">
          <label class="label" for="email">Your Email*</label>
          <input
            type="email"
            name="email"
            id="email"
            required
            :class="{ email, error: !formData.email.valid }"
            v-model="formData.email.value"
          />
          <transition
            enter-active-class="animated fadeInDown"
            leave-active-class="animated fadeOutUp"
            mode="out-in"
          >
            <div class="error-cont" v-if="!formData.email.valid">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
              >
                <title>ic_error_48px</title>
                <g fill="#ED4337">
                  <path
                    d="M24 4C12.96 4 4 12.95 4 24s8.96 20 20 20 20-8.95 20-20S35.04 4 24 4zm2 30h-4v-4h4v4zm0-8h-4V14h4v12z"
                  />
                </g>
              </svg>
              <p>{{ this.emailError }}</p>
            </div>
          </transition>
        </div>
      </div>
      <div class="row">
        <div class="col-sm">
          <label class="label" for="phone">Your Phone Number</label>
          <input
            type="text"
            name="phone"
            id="phone"
            v-model="formData.phone"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm">
          <label class="label" for="textarea">Message</label>
          <textarea
            class="message"
            name="textarea"
            id="textarea"
            v-model="formData.message.text"
            :maxlength="formData.message.maxlength"
          ></textarea>
          <span class="counter"
            >{{ formData.message.text.length }} /
            {{ formData.message.maxlength }}</span
          >
        </div>
      </div>

      <div style="text-align: center; margin-top: 1.5em">
        <p style="text-align: center; font-size: 0.9em">
          By completing this form you are agreeing to Russell Brown Interior's
          <router-link to="/privacy-policy">privacy policy</router-link>.
        </p>
        <input
          type="submit"
          :class="{ buttonerror: !this.validation }"
          value="Submit"
        />
        <transition name="fadeIn" mode="out-in">
          <div class="form-error" v-if="!this.validation">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 48 48"
            >
              <title>ic_error_48px</title>
              <g fill="#ED4337">
                <path
                  d="M24 4C12.96 4 4 12.95 4 24s8.96 20 20 20 20-8.95 20-20S35.04 4 24 4zm2 30h-4v-4h4v4zm0-8h-4V14h4v12z"
                />
              </g>
            </svg>
            <p>There is an error with your details, please check any errors.</p>
          </div>
        </transition>
      </div>
      <transition
        enter-active-class="animated bounceIn"
        leave-active-class="animated bounceOut"
        mode="out-in"
      >
        <div class="submitting-info" v-if="this.submitSucess">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <g fill="#7fdb85">
              <path
                d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
              />
            </g>
          </svg>
          <div class="text">
            <h3>
              Thank you for contacting us! Russell will get back to you as soon as
              possible.
            </h3>
          </div>
        </div>
      </transition>
    </fieldset>
  </form>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      subscribeMail: Boolean,
      privacyPolicy: Boolean,
      loader: false,
      loadedP2: false,
      formid: 0,
      submitSucess: false,
      validation: true,
      isStickyActive: false,
      submitting: false,
      firstname: "firstName",
      email: "email",
      formData: {
        firstname: {
          value: "",
          valid: true,
        },
        lastname: {
          value: "",
          valid: true,
        },
        phonenumber: "",
        email: {
          value: "",
          valid: true,
          empty: false,
        },
        message: {
          text: "",
          maxlength: 255,
        },
        gdpr: {
          privacypolicy: true,
          privacypolicycheck: true,
        },
      },
    };
  },
  methods: {
    // submit form handler
    onSubmit: function () {
      /* Run Validation for required fields */
      if (this.formData.firstname.value.length === 0) {
        this.formData.firstname.valid = false;
        /* Stop */
      }

      if (!this.formData.gdpr.privacypolicy) {
        this.formData.gdpr.privacypolicycheck = false;
      } else {
        this.formData.gdpr.privacypolicycheck = true;
      }

      this.validEmail(this.formData.email.value);

      if (
        !this.formData.firstname.valid ||
        !this.formData.email.valid ||
        !this.formData.gdpr.privacypolicy
      ) {
        this.validation = false;
        /* Stop */
      } else {
        /* Just in Case Reset */
        this.validation = true;
        this.submitting = true;
        this.submitformData();
      }
    },

    reValidator: function () {
      /* Run Validation for required fields */
      if (this.validation === false) {
        if (this.formData.firstname.value.length === 0) {
          this.formData.firstname.valid = false;
          /* Stop */
        }
        if (!this.formData.gdpr.privacypolicy) {
          this.formData.gdpr.privacypolicycheck = false;
        } else {
          this.formData.gdpr.privacypolicycheck = true;
        }

        this.validEmail(this.formData.email.value);

        if (
          !this.formData.firstname.valid ||
          !this.formData.email.valid ||
          !this.formData.gdpr.privacypolicy
        ) {
          this.validation = false;
          /* Stop */
        } else {
          /* Just in Case Reset */
          this.validation = true;
          this.submitformData();
        }
      }
    },

    validationReset: function () {

      if (this.validation === false) {
        this.validation = !this.validation;
      }

    },

    validEmail: function (email) {
      if (email.length === 0) {
        this.formData.email.valid = false;
        this.emailError = "Email address is required for us to contact you.";
      } else {
        this.formData.email.valid = true;
      }
    },

    submitformData: function () {
      const form = new FormData();
      form.append("your-name", this.formData.firstname.value);
      form.append("your-email", this.formData.email.value);
      form.append("your-phone", this.formData.phonenumber);
      form.append("message", this.formData.message.text);

      axios
        .post(
          "https://russell-brown.co.uk/wp-json/contact-form-7/v1/contact-forms/5/feedback",
          form
        )
        .then((response) => {
          console.log(form);
          console.log(response);
          if (response.data.status == "mail_sent") {
            this.submitSucess = true;
          } else {
            this.submitSucess = false;
            this.validation = false;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },

  watch: {
    "formData.email.value"(value) {
      this.validEmail(value);
      this.reValidator();
    },
    "formData.firstname.value"(value) {
      if (value.length === 0) {
        this.formData.firstname.valid = false;
      } else {
        this.formData.firstname.valid = true;
        this.validation = true;
      }
      this.reValidator();
    },
    "formData.lastname.value"(value) {
      if (value.length === 0) {
        this.formData.lastname.valid = false;
      } else {
        this.formData.lastname.valid = true;
        this.validation = true;
      }
      this.reValidator();
    },
    "formData.gdpr.privacypolicy"(value) {
      if (value === true) {
        this.formData.gdpr.privacypolicycheck = true;
      }
      this.reValidator();
    },
  },
};
</script>