<template>
  <div class="page home">
    <div class="header header-center">
      <div class="header-body text-center">
        <div class="text-center">
          <h3 class="page-title">Contact Russell</h3>
          <h1 class="hero-header">Pure quality is timeless</h1>
          <a href="#" v-scroll-to="'#start'">
            <img
              src="/flex-content/uploads/2020/11/downarrow.svg"
              class="down"
            />
          </a>
        </div>
      </div>
    </div>

    <div class="page-body" id="start">
      <intersect
        :threshold="[0.25, 0.25]"
        @enter="bigNav(false)"
        @leave="bigNav(true)"
      >
        <div class="page-body-inner">
          <section class="col-2-contact">
            <div class="col">
              <img
                class="fleur"
                src="/flex-content/uploads/2020/11/fleur_Top.svg"
                style="margin-bottom: 0.3em"
              />
              <h2>Contact Russell Brown Interiors</h2>
              <p>
                We are proud to deliver the highest standards of quality and
                craftsmanship. Each home we touch is finished with care, not
                haste. Please contact us to arrange a consultation.
              </p>
              <br />
              <div class="contact-container">
                <img src="/flex-content/uploads/2020/11/mail.svg" />
                <a href="mailto: enquiries@russell-brown.co.uk"
                  >enquiries@russell-brown.co.uk</a
                >
              </div>
              <div class="contact-container">
                <img src="/flex-content/uploads/2020/11/callblue.svg" />
                <a href="tel: 01283 814 111"
                  >01283 814 111</a
                >
              </div>
            </div>
            <div class="col">
                <contactform />
            </div>
          </section>
        </div>
      </intersect>
    </div>
    <div class="hero-slider">
      <div class="overlay"></div>
      <div class="slideshow">
        <div class="container">
          <div class="slide">
            <transition name="fadeInSlow">
              <div>
                <imgdivloader
                  :classes="'bg AppImage'"
                  :lazySrc="pageData.fimg_url"
                />
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <footerdiv />
  </div>
</template>
<script>
import footerdiv from "@/partials/footer.vue";
import imgdivloader from "@/partials/ImgLoaderDiv.vue";
import intersect from "vue-intersect";
import contactform from "@/partials/contactform.vue";
export default {
  data() {
    return {
      portfolioData: null,
    };
  },
  components: {
    footerdiv,
    imgdivloader,
    intersect,
    contactform
  },
  computed: {
    pageData() {
      var routePath = this.$route.fullPath;

      if (routePath === "/") {
        var permaLink = "/";
      } else {
        permaLink = this.$route.path + "/";
      }

      return this.$store.getters.getPage(permaLink);
    },
  },
  mounted() {
    this.bigNav(false);
  },
  methods: {
    bigNav(value) {
      this.$store.commit("TOGGLE_NAVBAR_SIZE", value);
    },
  },
  metaInfo() {
    return {
      title: this.pageData.yoast_meta.yoast_wpseo_title,
      link: [
        {
          rel: "canonical",
          href: "https://russell-brown.co.uk" + this.$route.path,
        },
      ],
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.pageData.yoast_meta.yoast_wpseo_metadesc,
        },

        // Twitter Meta Tags
        {
          vmid: "twitter:card",
          name: "twitter:card",
          content: "summary_large_image",
        },

        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: this.pageData.yoast_meta.yoast_wpseo_title,
        },

        {
          vmid: "twitter:image",
          name: "twitter:image",
          content: this.pageData.fimg_url,
        },

        {
          vmid: "twitter:description",
          name: "twitter:description",
          content: this.pageData.yoast_meta.yoast_wpseo_metadesc,
        },
        //Open Graph Integrations
        {
          vmid: "og:image",
          property: "og:image",
          content: this.pageData.fimg_url,
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.pageData.yoast_meta.yoast_wpseo_title,
        },
        { vmid: "og:locale", property: "og:locale", content: "en_GB" },
        { vmid: "og:type", property: "og:type", content: "article" },
        { vmid: "og:url", property: "og:url", content: this.pageData.link },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: "Russell Brown Interiors",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.pageData.yoast_meta.yoast_wpseo_metadesc,
        },
        {
          vmid: "article:modified_time",
          name: "article:modified_time",
          content: this.pageData.modified_gmt + "+00:00",
        },
        {
          vmid: "article:published_time",
          name: "article:published_time",
          content: this.pageData.date_gmt + "+00:00",
        },
        {
          vmid: "og:updated_time",
          name: "og:updated_time",
          content: this.pageData.modified_gmt + "+00:00",
        },
      ],
    };
  },
};
</script>